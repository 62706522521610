<template>
  <div class="box">
    <div class="footer">
      <div class="left-links">
        <!--
        <a href="/impressum">{{ $t("impressum") }}</a>
        <a href="/datenschutz">{{ $t("privacy") }}</a>
        <a href="/kontakt">{{ $t("placeholder1") }}</a>
        -->
      </div>

      <div>
        <div class="footer-logo">
          <img src="https://support.robertsspaceindustries.com/hc/article_attachments/360022704853/MadeByTheCommunity_White.png" alt="Made by the Community" />
        </div>
        <div class="footer-disclaimer">
          <p> {{ $t("disclaimer") }} </p>
        </div>
      </div>

      <div class="right-links">
        <!--
        <a href="/agb">{{ $t("terms") }}</a>
        <a href="/kontakt">{{ $t("contact") }}</a>
        <a href="/kontakt">{{ $t("placeholder2") }}</a>
        -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}



.box {
  background-color: #2d2e23;
  padding-top: 5%;

  z-index: 3;
  position: relative;
}

.left-links,
.right-links {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  font-size: 13px;
  width: 40vh;
}

.left-links a,
.right-links a {
  margin-bottom: 10px;
  padding: 0.5vh;
}
.left-links {
  margin-left: 10vw;
}
.right-links {
  margin-right: 10vw;
}

.footer-logo img {
  margin-bottom: 10px;
  width: 15vw;
}

.footer-disclaimer {
  padding-top: 1%;
  font-size: 12px;
}



@media screen and (max-width: 1024px){
  .left-links,  .right-links {
    margin-top: 0%;
  }
  .left-links, .right-links {
    margin-right:auto;
    margin-left:auto;
  }
}
</style>

<script lang="ts">
export default {
  name: 'DatenschutzComponent',
};
</script>
